import React from 'react';
import { navigate } from 'gatsby';
import { Layout, Link } from '../components';
import FourOhFourImage from '../images/404.png';
import './thank-you.scss';

const ThankYou = ({ location }) => {
  const goBack = event => {
    event.preventDefault();
    if (window && window.history) window.history.go(-1);
  };

  return (
    <Layout location={location}>
      <div className="wrapper thank-you-wrap">
        <img className="thank-you-image" src={FourOhFourImage} alt="Thank You" />
        <div className="thank-you-text">
          <h2>Thank you, one of our team will be in touch soon</h2>
          <Link className="button" to="/" onClick={goBack}>
            Go back
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default ThankYou;
